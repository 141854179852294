import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 200px;
background: transparent;
`;

export const ImageLogo = styled.img`
width: 150px;
`;